var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form',[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":_vm.$getVisibleNames('manual.dimension', false, 'Dimensión')}},[_c('SentenceForm',{attrs:{"first_content":_vm.rubric_instance.action,"second_content":_vm.rubric_instance.content,"third_content":_vm.rubric_instance.condition,"full_sentence":_vm.rubric_instance.full_sentence,"verb":_vm.rubric_instance.verb,"third_content_comment":""},on:{"update":_vm.slotUpdateSentence}})],1),_c('b-tab',{attrs:{"title":"Detalles"}},[(_vm.institution && _vm.institution.show_evaluation_criteria_macro)?_c('b-form-group',{attrs:{"label":`${_vm.$getVisibleNames(
            'teaching.evaluationcriteriamacro',
            true,
            'Criterios de Evaluación Macro'
          )}:`,"label-for":"select-evaluation-criterias-macro","label-cols":"0","label-cols-sm":"3"}},[(_vm.evaluationCriteriasMacrosFilter.length > 0)?[_c('b-form-select',{staticClass:"select-form",attrs:{"id":"select-evaluation-criterias-macro","multiple":"","select-size":_vm.evaluationCriteriasMacrosFilter.length > 6
                  ? 6
                  : _vm.evaluationCriteriasMacrosFilter.length + 1,"size":"sm"},model:{value:(_vm.rubric_instance.evaluation_criterias_macro),callback:function ($$v) {_vm.$set(_vm.rubric_instance, "evaluation_criterias_macro", $$v)},expression:"rubric_instance.evaluation_criterias_macro"}},_vm._l((_vm.evaluationCriteriasMacrosFilter),function(item){return _c('b-form-select-option',{key:item.id,staticClass:"select-option-macro",attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.order)+" - "+_vm._s(item.full_sentence)+" ")])}),1)]:[_c('div',[_vm._v("N/A")])]],2):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$getVisibleNames(
              'teaching.evaluationcriteriamicro',
              true,
              'Criterios de Evaluación Micro'
            ) + ':',"label-for":"select-evaluation-criterias-micro","label-cols":"0","label-cols-sm":"3"}},[(_vm.filterECMicro.length > 0)?[_c('b-form-select',{staticClass:"select-form",attrs:{"id":"select-evaluation-criterias-micro","select-size":_vm.evaluationCriteriasMicrosFilter.length > 6
                  ? 6
                  : _vm.evaluationCriteriasMicrosFilter.length + 2,"size":"sm","multiple":""},model:{value:(_vm.rubric_instance.evaluation_criterias_micro),callback:function ($$v) {_vm.$set(_vm.rubric_instance, "evaluation_criterias_micro", $$v)},expression:"rubric_instance.evaluation_criterias_micro"}},_vm._l((_vm.filterECMicro),function(item){return _c('b-form-select-option-group',{key:item.id,attrs:{"label":item.name}},_vm._l((item.options),function(ecm){return _c('b-form-select-option',{key:ecm.id,staticClass:"select-option-micro",attrs:{"value":ecm.id,"title":ecm.name}},[_vm._v(" "+_vm._s(item.order)+"."+_vm._s(ecm.order)+" "+_vm._s(ecm.full_sentence)+" ")])}),1)}),1)]:[_c('div',[_vm._v("N/A")])]],2),_c('b-form-group',{attrs:{"label":"Ponderación","label-for":"input-weighing","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-weighing","state":_vm.validateState('weighing'),"aria-describedby":"input-weighing-feedback","size":"sm","type":"number"},model:{value:(_vm.$v.rubric_instance.weighing.$model),callback:function ($$v) {_vm.$set(_vm.$v.rubric_instance.weighing, "$model", $$v)},expression:"$v.rubric_instance.weighing.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-weighing-feedback"}},[(
                _vm.rubric_instance.weighing > _vm.max_weighing ||
                _vm.rubric_instance.weighing < 0
              )?_c('span',[_vm._v(" Este campo debe ser un valor entre 0 y "+_vm._s(_vm.max_weighing)+". ")]):_c('span',[_vm._v(" Este campo debe ser un número entero positivo. ")])])],1)],1)],1),_c('div',{staticClass:"row"},[(_vm.show_delete_button && !isNaN(_vm.rubric_instance.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deleteObservationInstance}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.saveObservationInstance}},[_vm._v("Guardar")])],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }