<template>
  <div>
    <b-form>
      <!--********* Reemplazando antiguo indicador en Rúbrica **********-->
      <!-- <b-form-group
        label="Dimensión"
        label-for="input-indicator"
        label-cols="4"
        label-cols-sm="3"
      >
        <b-form-input
          id="input-indicator"
          v-model="$v.rubric_instance.indicator.$model"
          :state="validateState('indicator')"
          aria-describedby="input-indicator-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-indicator-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group> -->
      <!-- *********** Agregando Formulario de oración ************ -->
      <b-tabs content-class="mt-3">
        <b-tab
          :title="$getVisibleNames('manual.dimension', false, 'Dimensión')"
        >
          <SentenceForm
            :first_content="rubric_instance.action"
            :second_content="rubric_instance.content"
            :third_content="rubric_instance.condition"
            :full_sentence="rubric_instance.full_sentence"
            :verb="rubric_instance.verb"
            third_content_comment
            @update="slotUpdateSentence"
          ></SentenceForm>
        </b-tab>
        <b-tab title="Detalles">
          <b-form-group
            :label="`${$getVisibleNames(
              'teaching.evaluationcriteriamacro',
              true,
              'Criterios de Evaluación Macro'
            )}:`"
            label-for="select-evaluation-criterias-macro"
            label-cols="0"
            label-cols-sm="3"
            v-if="institution && institution.show_evaluation_criteria_macro"
          >
            <template v-if="evaluationCriteriasMacrosFilter.length > 0">
              <b-form-select
                id="select-evaluation-criterias-macro"
                v-model="rubric_instance.evaluation_criterias_macro"
                multiple
                :select-size="
                  evaluationCriteriasMacrosFilter.length > 6
                    ? 6
                    : evaluationCriteriasMacrosFilter.length + 1
                "
                size="sm"
                class="select-form"
              >
                <b-form-select-option
                  class="select-option-macro"
                  v-for="item in evaluationCriteriasMacrosFilter"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.order }} - {{ item.full_sentence }}
                </b-form-select-option>
              </b-form-select>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-form-group>
          <b-form-group
            :label="
              $getVisibleNames(
                'teaching.evaluationcriteriamicro',
                true,
                'Criterios de Evaluación Micro'
              ) + ':'
            "
            label-for="select-evaluation-criterias-micro"
            label-cols="0"
            label-cols-sm="3"
          >
            <template v-if="filterECMicro.length > 0">
              <b-form-select
                id="select-evaluation-criterias-micro"
                v-model="rubric_instance.evaluation_criterias_micro"
                :select-size="
                  evaluationCriteriasMicrosFilter.length > 6
                    ? 6
                    : evaluationCriteriasMicrosFilter.length + 2
                "
                size="sm"
                multiple
                class="select-form"
              >
                <b-form-select-option-group
                  v-for="item in filterECMicro"
                  :key="item.id"
                  :label="item.name"
                >
                  <b-form-select-option
                    class="select-option-micro"
                    v-for="ecm in item.options"
                    :value="ecm.id"
                    :key="ecm.id"
                    :title="ecm.name"
                  >
                    {{ item.order }}.{{ ecm.order }} {{ ecm.full_sentence }}
                  </b-form-select-option>
                </b-form-select-option-group>
              </b-form-select>
            </template>
            <template v-else>
              <div>N/A</div>
            </template>
          </b-form-group>
          <!-- <b-form-group
            :label="`${$getVisibleNames(
              'manual.indicador_de_logro',
              false,
              'Indicador de Logro'
            )}`"
            label-for="input-evaluation-criteria"
            label-cols="4"
            label-cols-sm="3"
          >
            <b-form-select
              :id="'input-evaluation-criteria'"
              v-model="$v.rubric_instance.evaluation_criteria.$model"
              :state="validateState('evaluation_criteria')"
              aria-describedby="input-evaluation-criteria-feedback"
              size="sm"
              :class="{ 'border-error': !rubric_instance.evaluation_criteria }"
            >
              <b-form-select-option
                v-for="criteria in evaluationCriteriaList"
                :key="criteria.id"
                :value="criteria.id"
                >{{ criteria.action }} {{ criteria.content }}
                {{ criteria.condition }}</b-form-select-option
              >
            </b-form-select>
            <template>
              <b-form-invalid-feedback id="input-evaluation-criteria-feedback"
                >Este campo es obligatorio.</b-form-invalid-feedback
              >
            </template>
            <template v-if="evaluationCriteriaList.lenght == 0">
              <div class="error-msg text-danger">
                Debe crear al menos un Indicador de Logro para continuar.
              </div>
            </template>
          </b-form-group> -->
          <b-form-group
            label="Ponderación"
            label-for="input-weighing"
            label-cols="4"
            label-cols-sm="3"
          >
            <b-form-input
              id="input-weighing"
              v-model="$v.rubric_instance.weighing.$model"
              :state="validateState('weighing')"
              aria-describedby="input-weighing-feedback"
              size="sm"
              type="number"
            ></b-form-input>
            <b-form-invalid-feedback id="input-weighing-feedback">
              <span
                v-if="
                  rubric_instance.weighing > max_weighing ||
                  rubric_instance.weighing < 0
                "
              >
                Este campo debe ser un valor entre 0 y
                {{ max_weighing }}.
              </span>
              <span v-else>
                Este campo debe ser un número entero positivo.
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-tab>
      </b-tabs>

      <div class="row">
        <div
          v-if="show_delete_button && !isNaN(rubric_instance.id)"
          class="col"
          style="text-align: left"
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            @click="deleteObservationInstance"
            >Eliminar</b-button
          >
        </div>
        <div v-if="show_save_button" class="col" style="text-align: right">
          <b-button class="mr-1" size="sm" @click="saveObservationInstance"
            >Guardar</b-button
          >
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  maxValue,
  integer,
} from "vuelidate/lib/validators";

export default {
  name: "InstanceForm",
  mixins: [validationMixin],
  components: {
    SentenceForm: () => import("@/components/reusable/SentenceForm"),
  },
  props: {
    evaluation_id: {
      type: Number,
      required: true,
    },
    observation_instrument_id: {
      type: Number,
      required: true,
    },
    ObservationInstance: {
      type: Object,
    },
    max_weighing: {
      type: Number,
      default: 100,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
    default_order: {
      type: Number,
    },
  },
  data() {
    return {
      rubric_instance: {
        id: this.ObservationInstance
          ? this.ObservationInstance.id
          : generateUniqueId(),
        //indicator: this.ObservationInstance ? this.ObservationInstance.indicator : "",
        action: this.ObservationInstance ? this.ObservationInstance.action : "",
        content: this.ObservationInstance
          ? this.ObservationInstance.content
          : "",
        condition: this.ObservationInstance
          ? this.ObservationInstance.condition
          : "",
        full_sentence: this.ObservationInstance
          ? this.ObservationInstance.full_sentence
          : "",
        verb: this.ObservationInstance ? this.ObservationInstance.verb : null,
        weighing: this.ObservationInstance
          ? this.ObservationInstance.weighing
          : 0,
        order: this.ObservationInstance
          ? this.ObservationInstance.order
          : this.default_order,
        observation_instrument: this.ObservationInstance
          ? this.ObservationInstance.observation_instrument
          : this.observation_instrument_id,
        evaluation_criterias_micro: this.ObservationInstance
          ? this.ObservationInstance.evaluation_criterias_micro
          : [],
        evaluation_criterias_macro: this.ObservationInstance
          ? this.ObservationInstance.evaluation_criterias_macro
          : [],
      },
      base_sentence_toggle: this.ObservationInstance
        ? this.ObservationInstance.content
          ? true
          : false
        : true,
    };
  },
  validations() {
    return {
      rubric_instance: {
        weighing: {
          required,
          minValue: minValue(0),
          integer,
          maxValue: maxValue(this.max_weighing),
        },
        observation_instrument: { required },
        // evaluation_criteria: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      rubrics: names.OBSERVATION_INSTRUMENTS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      evaluations: names.EVALUATIONS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      evaluationCriteriasMicros: names.EVALUATION_CRITERIAS,
      section_evaluations: names.SECTION_EVALUATIONS,
      studyUnits: names.STUDY_UNITS,
      institution: "getInstitution",
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.observation_instrument_id);
    },
    evaluation() {
      if (!this.rubric) return null;
      return this.evaluations.find((x) => x.id == this.rubric.evaluation);
    },
    evaluationCriteriasMacrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_macro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMacros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.matter > b.matter) return 1;
          if (a.matter < b.matter) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    evaluationCriteriasMicrosFilter() {
      let list = [];
      if (!this.filter_section_evaluation) return [];
      this.filter_section_evaluation.forEach((x) => {
        x.evaluation_criterias_micro.forEach((ra) => {
          list.push(ra);
        });
      });
      const unique = [...new Set(list)];
      return this.evaluationCriteriasMicros
        .filter((x) => unique.includes(x.id))
        .sort(function (a, b) {
          if (a.study_unit > b.study_unit) return 1;
          if (a.study_unit < b.study_unit) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    filterECMicro() {
      let list = [];
      this.studyUnits.forEach((study_unit) => {
        const evaluation_criterias =
          this.evaluationCriteriasMicrosFilter.filter(
            (x) => x.study_unit == study_unit.id
          );
        if (evaluation_criterias.length > 0)
          list.push({
            id: study_unit.id,
            name: String("N°" + study_unit.order + ": " + study_unit.name),
            order: study_unit.order,
            options: this.evaluationCriteriasMicrosFilter
              .filter((x) => x.study_unit == study_unit.id)
              .sort(function (a, b) {
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return a.full_sentence
                  .toLowerCase()
                  .localeCompare(b.full_sentence.toLowerCase());
              }),
          });
      });
      return list;
    },
    filter_section_evaluation() {
      return this.section_evaluations.filter(
        (x) => x.evaluation == this.evaluation_id
      );
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.rubric_instance[key];
      return $dirty ? !$error : null;
    },
    slotUpdateSentence(sentence) {
      this.rubric_instance.verb = sentence.verb;
      this.rubric_instance.action = sentence.first_content;
      this.rubric_instance.content = sentence.second_content;
      this.rubric_instance.condition = sentence.third_content;
      this.rubric_instance.full_sentence = sentence.full_sentence;
      this.base_sentence_toggle = sentence.base_sentence_toggle;
      this.$emit("changed", this.rubric_instance);
    },
    saveObservationInstance() {
      this.$v.rubric_instance.$touch();
      if (this.$v.rubric_instance.$anyError) {
        return;
      }
      if (this.base_sentence_toggle) {
        this.rubric_instance.full_sentence = String(
          this.rubric_instance.action +
            " " +
            this.rubric_instance.content +
            " " +
            this.rubric_instance.condition
        );
        if (isNaN(this.rubric_instance.id)) this.createObservationInstance();
        else this.updateObservationInstance();
      } else {
        if (
          this.rubric_instance.full_sentence ||
          !this.rubric_instance.full_sentence.trim() == ""
        ) {
          if (isNaN(this.rubric_instance.id)) this.createObservationInstance();
          else this.updateObservationInstance();
        }
      }
    },
    createObservationInstance() {
      this.$store
        .dispatch(names.POST_RUBRIC_INSTANCE, this.rubric_instance)
        .then((response) => {
          this.rubric_instance = response;
          this.$emit("created", response);
          toast(
            this.$getVisibleNames("manual.dimension", false, "Dimensión") +
              " creada."
          );
        });
    },
    updateObservationInstance() {
      this.$store
        .dispatch(names.UPDATE_OBSERVATION_INSTANCE, this.rubric_instance)
        .then((response) => {
          this.rubric_instance = response;
          this.$emit("updated", response);
          toast(
            this.$getVisibleNames("manual.dimension", false, "Dimensión") +
              " actualizada."
          );
        });
    },
    deleteObservationInstance() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Indicador de la Rúbrica?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              names.DELETE_OBSERVATION_INSTANCE,
              this.rubric_instance.id
            )
            .then(() => {
              this.$emit("deleted", this.rubric_instance);
              toast(
                this.$getVisibleNames("manual.dimension", false, "Dimensión") +
                  " eliminada."
              );
            });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.border-error {
  border: 1px solid red;
}
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: -16px;
}
.error-msg {
  font-size: var(--secondary-font-size);
}
</style>